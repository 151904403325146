import React, { useState, useEffect } from 'react'
import Connection from '../components/booth/Connection'
import Upload from '../components/booth/Upload'
import Cart from '../components/booth/Cart'
import Email from '../components/booth/Email'
import Home from '../components/booth/Home'
import Payment from '../components/booth/Payment'
import Print from '../components/booth/Print'
import Loader from '../components/Loader'
import Gradient from '../components/Gradient'
import Error from '../components/Error'
import { findBooth, createSession, findSession, applyPromo, createPayment, updatePayment, logSession } from '../api'
import { preparePhoto } from '../utils'
import Timeout from '../components/booth/Timeout'

function Booth() {
  const [booth, setBooth] = useState(null)
  const [session, setSession] = useState(null)
  const [payment, setPayment] = useState(null)
  const [photos, setPhotos] = useState([])
  const [step, setStep] = useState(null)

  useEffect(() => { fetchConfig() }, [])
  const products = booth && booth.config && booth.config.variables.products
  const fetchConfig = async () => {
    setBooth(await findBooth())
  }
  const startSession = async () => {
    setSession(await createSession())
    setStep('connection')
  }
  const checkConnected = async () => {  
    await setSession(await findSession(session.id))
    if (session.connected) setStep('upload')
  }
  const tryCode = async promo => {
    const s = await applyPromo(session.id, promo)
    console.log(s)
    if (!s.error) setSession(s)
    return s
  }
  const checkPhotos = async () => {
    const s = await findSession(session.id)
    if (s.error) return
    if (s.photos.length === photos.length && s.photos.length > 0) return
    const newPhotos = await Promise.all(s.photos.filter(p => !photos.find(pp => pp.id === p.id)).map(preparePhoto))
    if (newPhotos.length > 0)
      await setPhotos([...photos, ...newPhotos.map(p => ({ ...p, product: products[0] }))])
    if (s.photos.length > 0 && step !== 'cart') setStep('cart')
  }
  const paid = async payment => {
    console.log(payment)
    const content = photos.map(p => ({ product: p.product.name, quantity: p.quantity, border: p.border }))
    setPayment(await createPayment({ ...payment, session: session.id, booth: booth.id, company: booth.company.id, promo: (session.promo || {}).id, content }))
    logSession(session.id, `payed`, payment)
    setStep('email')
  }
  const updateEmail = async (email, optin) => {
    try {
      await updatePayment(payment.id, { email, optin })
      logSession(session.id, `payed`, payment)
    }
    finally {
      setStep('print')
    }
  }
  const endSession = async () => {
    logSession(session.id, 'cancelled')
    setStep(null)
    setPhotos([])
    setSession(null)
  }
  const cleanSession = async () => {
    logSession(session.id, 'end')
    setStep(null)
    setPhotos([])
    setSession(null)
    setPayment(null)
  }
  if (!booth) return <Loader />
  if (booth.error) return <Error>{booth.error}</Error>
  if (session && session.error) return <Error>{session.error}</Error>
  const orderedPhotos = photos && photos.sort((a, b) => a.id - b.id).filter(p => p.quantity > 0)
  const comp = () => {
    switch (step) {
      case 'connection':
        return <Connection
                close={endSession}
                code={session.code}
                checkConnected={checkConnected} />
      case 'upload':
        return <Upload
                close={endSession}
                checkPhotos={checkPhotos} />
      case 'cart':
        return <Cart
                config={booth.config}
                close={endSession}
                code={session.code}
                promo={session.promo}
                photos={orderedPhotos}
                checkPhotos={checkPhotos}
                tryCode={tryCode}
                update={photo => setPhotos([...photos.filter(p => p.id !== photo.id), photo])}
                pay={() => setStep('payment')}
                log={m => logSession(session.id, m)} />
      case 'payment':
        return <Payment
                config={booth.config}
                promo={session.promo}
                photos={orderedPhotos}
                paid={paid}
                log={m => logSession(session.id, m)}
                close={endSession} />
      case 'email':
        return <Email
                config={booth.config}
                updateEmail={updateEmail} />
      case 'print':
        return <Print
                config={booth.config}
                photos={orderedPhotos}
                cleanSession={cleanSession}
                session={session}
                log={m => logSession(session.id, m)} />
      default:
        return <Home startSession={startSession} config={booth.config} logo={booth.company.logo} />
    }
  }
  return (
    <>
      {step !== 'print' &&
        <Timeout session={session} diff={JSON.stringify([session, photos, payment])} endSession={endSession} />
      }
      {comp()}
    </>
  )
}

export default function BoothWithGradient() {
  return (
    <div>
      <style dangerouslySetInnerHTML={{ __html: `
        html { font-size: 4.075vw }
        * { user-select: none; cursor: none }
      ` }} />
      <Gradient />
      <Booth />
      <div className="fixed bottom-0 h-4 mb-2 w-full bg-contain bg-no-repeat bg-center pointer-events-none z-50" style={{ backgroundImage: 'url("https://storage.googleapis.com/phoprint/LOGO_CHEERZ_BLEU_1_1_1_592b3e8903/LOGO-CHEERZ-BLEU-(1)-(1)-(1).png_LOGO_CHEERZ_BLEU_1_1_1_592b3e8903.png")' }} />
    </div>
  )
}
