import React, { useState } from 'react'
import Keyboard from '../Keyboard'
import Header from './Header'
import Title from '../Title'
import Checkbox from '../Checkbox'
import Button from '../Button'

const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,6})+$/

export default function Email({ session, updateEmail }) {
  const [optin, setOptin] = useState(false)
  const [email, setEmail] = useState('')
  return (
    <div className="w-screen h-screen px-2 pt-24 pb-12 flex flex-col justify-between items-center">
      <Title className="text-center m-2">
        Saisissez votre email pour recevoir votre ticket de caisse
      </Title>
      <Header
        title="Vos coordonnées"
        subtitle="Je renseigne mon email" />
      <Keyboard type="email" onType={v => setEmail(v)} />
      <div className="flex flex-row p-4 items-center">
        <p
          className="leading-tight text-sm ml-2"
          onClick={() => setOptin(!optin)}>
          En tapant sur Valider je reconnais avoir pris connaissance et accepter les conditions générales de vente et de la politique de confidentialité de Cheerz consultables sur cheerz.com
        </p>
      </div>
      <div className="flex flex-row justify-center items-center">
        <Button
          className="m-4 bg-blue-light text-blue"
          onClick={() => updateEmail(null, null)}>
          Passer
        </Button>
        <Button
          className="m-4"
          onClick={() => updateEmail(email, true)}
          disabled={!email.match(emailRegex)}>
          Valider
        </Button>
      </div>
    </div>
  )
}
