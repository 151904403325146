import React, { useState } from 'react'
import Button from '../Button'
import Title from '../Title'
import Header from './Header'
import Error from '../Error'
import { layoutStyle } from '../../utils'
import useInterval from '@use-it/interval'
import QRCode from 'qrcode.react'

const location = () => typeof window !== `undefined` ? window.location : { pathname: '', hostname: '', origin: '', search: '' }

const url = location().origin

export default function Connection({ close, code, checkConnected }) {
  const [fallback, setFallback] = useState(false)
  useInterval(checkConnected, 1500)
  if (!code) return <Error>Erreur de session</Error>
  return (
    <div className={layoutStyle}>
      <Header
        title="Connexion"
        subtitle="Je me connecte en moins de 30 sec"
        close={close} />
      {fallback
        ? (
          <>
            <Title className="text-center">Connectez-vous à l'adresse</Title>
            <div className="font-thin text-xl mb-10 tracking-wider">{url.replace('https://', '')}</div>
            <Title>Entrez le code</Title>
            <div className="font-thin text-3xl mb-10 tracking-widest">{code}</div>
            <Button variant="secondary" onClick={() => setFallback(false)}>
              J'utilise le QR Code
            </Button>
          </>
        )
        : (
          <>
            <Title>Scannez le QR Code</Title>
            <p>avec votre appareil photo</p>
            <a href={url === 'http://localhost:8000' && `${url}/?code=${code}`} target="_blank">
              <QRCode
                value={`${url}/?code=${code}`}
                size={512}
                level="L"
                includeMargin={false}
                fgColor="#0064F6"
                renderAs="svg"
                className="border-white bg-white h-40 w-40 p-4 m-8 rounded-sm" />
            </a>
            <Button variant="secondary" onClick={() => setFallback(true)}>
              Je n'arrive pas à scanner
            </Button>
          </>
      )}        
    </div>
  )
}
