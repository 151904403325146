import React from 'react'
import Photo from './Photo'
import { filters } from '../../filters'
import Checkbox from '../Checkbox'
import { DisableBodyScroll, formatPrice } from '../../utils'
import { FiCheck } from 'react-icons/fi'
import Button from '../Button'

function Filters({ photo, setFilter }) {
  return (
    <div className="flex mt-6">
      {filters.map(filter => 
        <div
          className="flex flex-col justify-center items-center"
          onClick={() => setFilter(filter)}>
          <svg
            viewBox={`0 0 200 200`}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            className="w-10 h-10 m-2 mb-1 rounded-full">
            <defs>
              <filter
                id={`filter-${filter.id}`}
                colorInterpolationFilters="sRGB"
                dangerouslySetInnerHTML={{ __html: filter.svg }} />
            </defs>
            <image
              filter={`url(#filter-${filter.id})`}
              xlinkHref={photo.url}
              x={0}
              y={0}
              width={200}
              height={200}
              preserveAspectRatio="xMidYMid slice" />
          </svg>
          <div className="text-xs" style={{ fontSize: '0.6rem' }}>
            {filter.name}
          </div>
        </div>
      )}
    </div>
  )
}

function Products({ photo, products, setProduct }) {
  return (
    <div className="flex mt-2 text-sm">
      {products.map(p => 
        <div key={p.name} className="flex bg-white m-4 rounded leading-tight" onClick={() => setProduct(p)}>
          {p.name === photo.product.name &&
            <div className="h-full border-r bg-blue-light px-2 -mr-1 flex items-center"><FiCheck /></div>
          }
          <div className="px-5 py-2">
            <div>{p.name}cm</div>
            <div className="text-xs opacity-75">{formatPrice(p.price)}</div>
          </div>
        </div>
      )}
    </div>
  )
}

export default function Editor({ photo, products, update, close }) {
  return (
    <>
    <DisableBodyScroll />
    <div
      className="fixed flex justify-center items-center h-screen w-screen top-0 left-0 z-30 pointer-events-auto bg-beige">
      <div className="flex flex-col justify-center items-center">
        <Photo
          photo={photo}
          size={800}
          onCrop={crop => update({ ...photo, crop })} />
        <Filters
          photo={photo}
          setFilter={filter => update({ ...photo, filter })} />
        <Products products={products} photo={photo} setProduct={p => update({ ...photo, product: p, crop: null })} />
        <div className="flex items-center mt-1 text-sm">
          <Checkbox checked={photo.border} onClick={() => update({ ...photo, border: !photo.border })} className="mr-3" />
          Bordure - Marie-louise
        </div>
        <div className="flex flex-row justify-center items-center">
          <Button
            className="mt-5 bg-blue-light text-blue"
            onClick={close}>
            Valider
          </Button>
        </div>
      </div>
    </div>
  </>
  )
}
