import React, { useState, useEffect } from 'react'
import Header from './Header'
import { layoutStyle, formatPrice, computePrice } from '../../utils'
import Title from '../Title'
import Button from '../Button'
import { pay } from '../../api'


export default function Payment({ photos, promo, config, paid, close }) {
  const [error, setError] = useState(false)
  const total = computePrice(promo, photos, config)
  const prints = photos.map(p => p.quantity).reduce((curr, acc) => curr + acc, 0)
  const triggerPayment = async () => {
    try {
      setError(false)
      const payment = await pay(total)
      console.log(payment)
      if (payment.error) return setError(payment.error)
      paid({ ...payment, ticket: payment.clientTicket, prints })
    } catch (error) {
      setError('Erreur de paiement')
    }
  }
  useEffect(() => {
    if (total <= 0) paid({ amount: 0, prints })
    else triggerPayment()
  }, [])
  return (
    <div className={layoutStyle}>
      <Header
        title="Paiement"
        subtitle="Je paie par CB" />
      <Title>Paiement de {formatPrice(total)}</Title>
      {!error &&
        <div className="text-center">Suivez les instructions sur le terminal de paiement</div>
      }
      {error &&
        <div className="text-center flex flex-col justify-center items-center">
          {error}
          <div className="flex">
            <Button onClick={close} className="m-4">
              Abandonner
            </Button>
            <Button onClick={triggerPayment} className="m-4">
              Réessayer
            </Button>
          </div>
          {/* <Button onClick={() => paid({ amount: total, prints, ticket: 'TICKET' })} className="m-4">
            Fake
          </Button> */}
        </div>
      }
    </div>
  )
}
